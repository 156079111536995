<div class="container">
  <div class="mb-4">
    <button class="btn__link btn__link--portfolio" (click)="getProjects()">All</button>
    <button class="btn__link btn__link--portfolio" (click)="js()">HTML/CSS/JS</button>
    <button class="btn__link btn__link--portfolio" (click)="angular()">Angular</button>
    <button class="btn__link btn__link--portfolio" (click)="wordpress()">Wordpress</button>
    <!-- <button class="btn__link btn__link--portfolio" (click)="animation()">Animation</button> -->
  </div>

  <div class="projects-container">
    <app-portfolio-card *ngFor="let project of projectFiltered" [portfolio]="project" class="project">
    </app-portfolio-card>
  </div>

</div>